import React from "react";
import ImageComponent from "../common/ImageComponent";
import { CalcWidthValue } from "../helper/CommonValue";
import useWindowDimensions from "../helper/dimensionsHelpers";
import classes from "./BlogComponenet.module.css";
import {
  Blog1_IMG
} from "../assets";
const BlogComponent = () => {
  const { width } = useWindowDimensions();

  return (
    <div
      style={{
        padding: `${CalcWidthValue(80, width)}px 0px`,
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "80%",
        }}
      >
        <ImageComponent
          src={Blog1_IMG}
          alt="Image"
          height="40%"
          width="100%"
        />
      </div>
      <div
        style={{
          padding: `20px 0px`,
          width: "80%",
          lineHeight: "30px",
        }}
      >
        <>
          <h2>
            <b>Why You Should Start Your Online Pharmacy in 2023?</b>
          </h2>
          <p
            style={{ marginBottom: "50px" }}
            className={classes["page_wrapper"]}
          >
            The pandemic has created an inclination towards online shopping. As
            you are selling essential commodities like medicines, it is no time
            better to accept a change of simultaneously starting your pharmacy
            online.
          </p>

          <p
            style={{ marginBottom: "10px" }}
            className={classes["page_wrapper"]}
          >
            If you have a local pharmacy, you will be surprised to know the
            growth opportunity you can create by starting an online business
            model. Find out why you should partner with Truetab to begin your
            online business.
          </p>

          <h4>Reasons to start an online pharmacy</h4>
          <p style={{ marginBottom: "10px" }}>
            If you have a local pharmacy and you are yet not sure why you should
            start selling your products online, here we will tell you why it is
            necessary:
          </p>
          <ol start={1}>
            <li>
              <strong>Quick accessibility</strong>
            </li>
          </ol>
          <div style={{ marginBottom: "50px" }}>
            <p className={classes["line_margin"]}>
              An online pharmacy caters to a delivery business with the primary
              motive of creating accessibility of several medicines for the
              masses.
            </p>
            <p className={classes["line_margin"]}>
              It eliminates the requirement of a consumer going through enormous
              stress to look for a medicine locally.
            </p>
            <p className={classes["line_margin"]}>
              If a person does not get access to medicine in an offline store,
              they tend to look from one pharmacy to another. However, if a
              medicine is not available online, it is immediately notified and
              updated for quick accessibility.
            </p>
            <p className={classes["line_margin"]}>
              Online pharmacies give customers an option to get prescription
              medicines delivered to their homes within 1-2 hours. Moreover,
              every medicine sold from an online store has detailed
              descriptions, reviews by clinical specialists, suggestions of
              substitutes, and more.
            </p>
          </div>
          <ol start={2}>
            <li>
              <strong>Easy to order</strong>
            </li>
          </ol>

          <p>A consumer who is ordering medicines for the first time has to:</p>

          <ul>
            <li>Sign up for the app</li>

            <li>Fill in required details like name and contact information</li>
            <li>Add a soft copy of their prescription</li>
            <li>Add additional information provided by the doctor</li>
            <li>Insurance information</li>
            <li>Add the products to the cart</li>
            <li>Purchase by adding a payment method</li>
          </ul>
          <p className={classes["line_margin"]}>
            An individual can also search for medicines by typing the names and
            adding them to the cart for purchase.
            <br />
            <br />A convenient online ordering process makes the user experience
            better for consumers. Truetab focuses on offering a sound ordering
            system for every individual and, in turn, makes the process
            effortless for pharmacies to manage orders.
          </p>
          <ol start={3}>
            <li>
              <strong>Efficient delivery system</strong>
            </li>
          </ol>
          <div className={classes["line_margin"]}>
            <p className={classes["line_margin"]}>
              Getting medicines delivered timely is one of the most crucial
              aspects for a consumer. Truetab aims to provide contactless
              delivery within 1-2 hours to ensure consumers quickly access
              necessities.
            </p>
            <p className={classes["line_margin"]}>
              To gain a competitive edge, Truetab has built a well-structured
              delivery system understanding the cruciality and urgency of
              essential commodities.
            </p>
            <p className={classes["line_margin"]}>
              An efficient delivery system requires technological upgraded
              software solutions with route optimization, order placement, order
              fulfilment, and real-time tracking facilities.
            </p>
          </div>
          <ol start={4}>
            <li>
              <strong>Service provided 24*7</strong>
            </li>
          </ol>
          <div>
            <p className={classes["line_margin"]}>
              Medicine requirements don’t have a specific time. Stepping outside
              in the middle of the night to find a pharmacy that offers
              round-the-clock services is inconvenient.
            </p>
            <p className={classes["line_margin"]}>
              With the online pharmacy delivery business model, Truetab
              eliminates the inconvenience faced by customers during dire times.
              An online pharmacy is accessible anytime to provide medicines
              whenever required.
            </p>
          </div>
          <ol start={5}>
            <li>
              <strong> Seamless order management</strong>
            </li>
          </ol>
          <p className={classes["line_margin"]}>
            A seamless platform helps consumers efficiently manage their orders.
            Through the Truetab app, one can place orders, track delivery
            partners, enable or disable pharmacy location, change menus, monitor
            order status, cancel orders, check reviews, rations, and more.
          </p>
          <p className={classes["line_margin"]}>
            The process makes it convenient for pharmacies to track their sales
            report, find the top spenders, and identify growth opportunities.
          </p>

          <h5>
            <b>How to start an online pharmacy delivery business?</b>
          </h5>
          <p className={classes["line_margin"]}>
            A local pharmacy requires a partner like Truetab that can open up
            the following opportunities:
          </p>
          <ul>
            <li>
              by creating a user-friendly platform that includes required data
              about medicines, prescription, insurance, supplier, available for
              customers
            </li>
            <li>
              of having a well-structured database that records consumer
              preferences, regular requirements, and repeat orders
            </li>
            <li>
              to provide a channel that creates customer engagement and quick
              grievance settlement
            </li>
            <li>
              to provide an efficient and contactless delivery system that
              offers quick access to necessities
            </li>
            <li>
              by having a dedicated team that offers support 24*7 to resolve
              technological or business-related problems
            </li>
          </ul>
          <p>
            A local pharmacy collaborating with Truetab can avail of all these
            features to increase their sales and brand recognition. Get in touch
            with us now to start an online pharmacy business.
          </p>
        </>
      </div>
    </div>
  );
};

export default BlogComponent;
