import React from "react";
import useWindowDimensions from "../helper/dimensionsHelpers";
import { CalcWidthValue } from "../helper/CommonValue";
import ImageComponent from "../common/ImageComponent";
import { ImArrowRight2 } from "react-icons/im";
import classes from "./NewsComponent.module.css";

const NewsCardList = ({ newsData }) => {
  const { width } = useWindowDimensions();
  return (
    <div
      style={{
        // height: CalcWidthValue(440, width),
        display: "flex",
        padding: CalcWidthValue(10, width),
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        border: "1px solid rgba(93, 35, 132, 0.3)",
        borderRadius: CalcWidthValue(8, width),
      }}
      className={classes["news-card-width"]}
    >
      <div
        style={{
          padding: CalcWidthValue(10, width),
          borderRadius: CalcWidthValue(8, width),
          height: CalcWidthValue(250, width),
          width: "100%",
          display: "flex",
          flex: 1,
        }}
      >
        <ImageComponent
          src={newsData?.imgUrl}
          alt="Image"
          height="100%"
          width="100%"
          style={{
            borderRadius: CalcWidthValue(8, width),
          }}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          // paddingTop: CalcWidthValue(10, width),
          width: "90%",
          // paddingLeft: 20,
          // paddingRight: 20,
        }}
      >
        <div
          style={{
            color: "rgba(51, 51, 51, 1)",
            fontSize: CalcWidthValue(22, width), //CalcWidthValue(22, width),
            fontWeight: 600,
          }}
        >
          {newsData?.title}
        </div>
        <div
          style={{
            color: "rgba(51, 51, 51, 1)",
            fontSize: CalcWidthValue(18, width), //CalcWidthValue(18, width),
            fontWeight: 400,
            paddingTop: CalcWidthValue(10, width),
          }}
        >
          {newsData?.desc}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          paddingBottom: CalcWidthValue(20, width),
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
        }}
      >
        <div
          style={{
            color: "rgba(51, 51, 51, 1)",
            fontSize: CalcWidthValue(14, width), //CalcWidthValue(14, width),
            fontWeight: 400,
            // lineHeight: CalcWidthValue(26, width),
          }}
        >
          {newsData?.uploadDate}
        </div>
        <div
          style={{
            color: "rgba(160, 197, 57, 1)",
            fontSize: CalcWidthValue(14, width),
            fontWeight: 600,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // gap: CalcWidthValue(10, width),
          }}
        >
          <a href={newsData?.redirectUrl}>
            Read More <ImArrowRight2 />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NewsCardList;
