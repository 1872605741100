import React, { useEffect } from "react";
import classes from "./AboutUsComponent.module.css";
import ImageComponent from "../common/ImageComponent";
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";

import {
  about_IMG,
  banner_IMG,
  bannerIMG3_IMG,
  Asset_IMG,
  playstor,
  apple,
} from "../assets";
const AboutUsComponent = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div
      style={{
        paddingTop: "10px",
        position: "relative",
      }}
    >
      <div
        className={`${classes["about-container"]} `}
        //animate__animated animate__fadeInBottomLeft animate__delay-0.7s
      >
        <p className={`${classes["about-header-text"]}`}>
          {/* <h2> */}
          Our <b style={{ color: "#a1c43a" }}>vision</b> is to enthuse respect
          and care to healthcare providers and end customers
          {/* </h2> */}
        </p>

        <p className={`${classes["aboutsub-header-text"]}`}>
          {/* <h2> */}
          Our <b style={{ color: "#522582" }}>mission</b> is to empower
          healthcare service providers and consumers with technology - one at a
          time.
          {/* </h2> */}
        </p>
        <div className={classes["about-sub-container-main"]}>
          <div className={classes["imageWrap"]}>
            <ImageComponent
              src={about_IMG}
              alt="img"
              width={"70%"}
              // height="60%"
            />
          </div>
          <div className={classes["textwrap"]}>
            <p className={`${classes["about-first-left-text-descwarp"]}`}>
              We are
              <b style={{ color: "#522582" }}>
                {" "}
                dreamers, innovators, and tech enthusiasts{" "}
              </b>{" "}
              on a mission to solve real-life challenges faced by healthcare
              providers and customers. At TrueTab, we are dedicated to
              transforming healthcare by making it simpler, more accessible, and
              more convenient for everyone. We believe that healthcare should be
              easy to access, reliable, and tailored to the modern world.
            </p>

            <p className={`${classes["about-first-left-text-descwarp"]}`}>
              Our platform empowers users to connect with doctors anytime,
              anywhere, providing instant digital prescriptions, seamless
              medicine ordering, and doorstep delivery. With 24/7 availability
              and multi-lingual support, we ensure that individuals can access
              quality healthcare whenever they need it, without the hassle of
              waiting or traveling.
            </p>

            <p className={`${classes["about-first-left-text-descwarp"]}`}>
              TrueTab is committed to bridging the gap between traditional
              practices and modern needs. We offer a comprehensive range of
              services that help both individuals and healthcare providers
              thrive. From online consultations with trusted professionals to
              easy health management tools, we make healthcare more efficient
              and user-friendly.
            </p>

            <p className={`${classes["about-first-left-text-descwarp"]}`}>
              We are more than just a healthcare service provider; we are your
              partner in health. Our mission is to empower individuals to take
              control of their health, while enabling businesses to grow and
              succeed in today’s fast-paced healthcare landscape. At TrueTab, we
              strive to revolutionize healthcare delivery and create a healthier
              future for all.
            </p>
          </div>
        </div>
      </div>
      <div className={`${classes["partoftextcontainer"]}`}>
        <p className={`${classes["partoftext"]}`}>
          Be A Part Of India’s
          <b style={{ color: "#522582" }}> Healthcare Revolution</b>
        </p>
        <div className={`${classes["downloadtext"]}`}>
          Download the app now!
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
            margin: "20px",
          }}
        >
          <div className={`${classes["buttoncontainer"]}`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  gap: "10px",
                  // textAlign: "center",
                }}
              >
                <ImageComponent
                  src={playstor}
                  alt="img"
                  width={"20px"}
                  height={"20px"}
                />
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontSize: 8, color: "white" }}>Get it On</div>
                  <div style={{ fontSize: 10, color: "white" }}>
                    Google Play store
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${classes["buttoncontainer"]}`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <ImageComponent
                  src={apple}
                  alt="img"
                  width={"20px"}
                  height={"20px"}
                />
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontSize: 8, color: "white" }}>Get it On</div>
                  <div style={{ fontSize: 10, color: "white" }}>
                    Google Play store
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div
                class="oppi_abs_num wow zoomIn"
                data-wow-delay="0.3s"
                style="visibility: visible; animation-delay: 0.3s; animation-name: zoomIn;"
              >
                <div class="dot">
                  <div class="round"></div>
                </div>
                <h1>1</h1>
              </div> */}
    </div>
  );
};

export default AboutUsComponent;
