import React from "react";
import classes from "./ShippingReturnPolicy.module.css";
import { CalcWidthValue } from "../helper/CommonValue";
import useWindowDimensions from "../helper/dimensionsHelpers";

const ShippingReturnPolicy = () => {
  const { width } = useWindowDimensions();

  return (
    <div
      style={{
        padding: `${CalcWidthValue(80, width)}px 0px`,
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        color: "#4c5267",
        fontFamily: "Poppins, sans-serif",
      }}
    >
      <div
        style={{
          padding: `20px 0px`,
          width: "80%",
          lineHeight: "30px",
        }}
      >
        <div className={classes["shipping_header"]}>
          Shipping and Returns Policy
        </div>
        <div className={classes["shipping_Sub_header"]}>Shipping Policy:</div>

        <p>
          Truetab is only engaged in providing placing a digital order of
          medicines from retail pharmacies. Hence Shipping is not applicable and
          return or Exchange/replacement of medicines as per the policy of
          respective stores from where customer has ordered.
        </p>
        <div className={classes["shipping_Sub_header"]}>Returns Policy:</div>

        <p>
          Truetab for Pharmacy doesn’t support refunds or cancellation pay backs
          as of now. For Retail Pharmacies: Once you receive payment in your
          account, you can transfer it back to user after the communication
          through WhatsApp/call using UPI/Wallets. For Customers: We do not
          handle refunds/cancellations of orders. You can contact the respective
          retail pharmacy from where you have ordered. The details of the same
          has been provided in your order details as well as WhatsApp
          notifications.
        </p>
      </div>
    </div>
  );
};

export default ShippingReturnPolicy;
