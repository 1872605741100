import React, { useState } from "react";
import useWindowDimensions from "../helper/dimensionsHelpers";
import vythtu from "../assets/truetab.mp4";
import classes from "./HomeComponent.module.css";
import ImageComponent from "../common/ImageComponent";
import {
  playstor,
  twoMobile,
  clock,
  our_choice,
  apple,
  dot,
  man_image,
  digital_priscription,
  languag,
  home,
  wellness,
  vital,
  circal,
  vedio,
  lastBackground,
  thumbnail,
  ordermedicine,
  BookLab,
  notification,
  wellnesshover,
  vitalhover,
  ordermedicineHover,
  booklabhover,
  notificationhover,
  health,
  healthhover,
} from "../assets";
import TrueTabNewsComponent from "./TrueTabNewsComponent";
import images from "../assets/svg";

const cardData = [
  {
    id: 1,
    title: "Wellness & Healthcare",
    subtitle:
      "Explore expert tips and services to maintain a healthier lifestyle.",
    normalImage: wellness, // Replace with actual image paths
    hoverImage: wellnesshover,
  },
  {
    id: 2,
    title: "Vital Recognition",
    subtitle: "Monitor and track your vitals easily with advanced tools.",
    normalImage: vital,
    hoverImage: vitalhover,
  },
  {
    id: 3,
    title: "Health Insurance",
    subtitle:
      "Access affordable and comprehensive health insurance options to secure your future.",
    normalImage: health,
    hoverImage: healthhover,
  },
  {
    id: 4,
    title: "Order Medicines",
    subtitle:
      "Get prescriptions filled and delivered to your doorstep with just a few clicks.",
    normalImage: ordermedicine,
    hoverImage: ordermedicineHover,
  },
  {
    id: 5,
    title: "Medicine Reminders",
    subtitle:
      "Never miss a dose with automated alerts tailored to your schedule.",
    normalImage: notification,
    hoverImage: notificationhover,
  },
  {
    id: 6,
    title: "Book a Lab Test",
    subtitle:
      "Schedule diagnostic tests with trusted labs and receive reports online.",
    normalImage: BookLab,
    hoverImage: booklabhover,
  },
];

const HomeComponent = () => {
  const { width } = useWindowDimensions();
  const { simply, thumbup, trusted, expand, convinence } = images;

  const [hoverStates, setHoverStates] = useState(1);

  const handleMouseEnter = (cardDetails) => {
    setHoverStates(cardDetails?.id);
  };

  return (
    <div
      style={{
        paddingTop: "10px",
      }}
    >
      <div className={`${classes["maincontainer"]}`}>
        <div className={`${classes["lefttextwap"]}`}>
          <p className={`${classes["bestdoctext"]}`}>
            THE <b style={{ color: "#A1C43A" }}> BEST DOCTOR </b>{" "}
            <span>ARE NOW A </span>
            <b style={{ color: "#522582" }}>CALL AWAY</b>
          </p>
          <p className={`${classes["indiantexttrusted"]}`}>
            India’s Trusted Modern Healthcare Platform
          </p>
          <p className={`${classes["contecttext"]}`}>
            Connect with specialist doctors anytime, anywhere. Get trusted
            advice, digital prescriptions, and multi-lingual support—because
            your health deserves 24/7 care.
          </p>
          {/* <div className={`${classes["buttonDivWap"]}`}>
            <div className={`${classes["buttoncontainer"]}`}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    textAlign: "center",
                    paddingLeft: "8px",
                    // paddingRight: "4px",
                  }}
                >
                  <ImageComponent
                    src={playstor}
                    alt="img"
                    width={"25px"}
                    height={"25px"}
                  />
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontSize: 8, color: "white" }}>Get it On</div>
                    <div style={{ fontSize: 10, color: "white" }}>
                      Google Play store
                    </div>
                  </div>
                </div>
              </div>
            </div>
           <div className={`${classes["buttoncontainer"]}`}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    textAlign: "center",
                    paddingLeft: "8px",
                    // paddingRight: "4px",
                  }}
                >
                  <ImageComponent
                    src={apple}
                    alt="img"
                    width={"25px"}
                    height={"25px"}
                  />
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontSize: 8, color: "white" }}>Get it On</div>
                    <div style={{ fontSize: 10, color: "white" }}>
                      Google Play store
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className={`${classes["vedioContainer"]}`}>
          <div
            style={{
              backgroundImage: `url(${vedio})`, // Replace with your image URL
              backgroundSize: "cover",
              paddingBottom: "20px",
              paddingRight: "12px",
            }}
          >
            <video
              className={`${classes["vedio"]}`}
              // autoPlay={false}
              controls
              poster={thumbnail}
            >
              <source src={vythtu} type="video/mp4" />
            </video>
          </div>
        </div>
        {/* <div className={`${classes["vedioContainer"]}`}>
          <img src={vedio} className={`${classes["vediobg"]}`} />
          <video className={`${classes["vedio"]}`} autoPlay={true}>
            <source src={vythtu} type="video/mp4" />
          </video>
        </div> */}
      </div>
      <div style={{ position: "absolute", top: "120px" }}>
        <ImageComponent src={dot} height="60px" width="30px" />
      </div>
      <div className={`${classes["docandCall"]}`}>
        <div className={`${classes["leftwarp247"]}`}>
          <p className={`${classes["text247"]}`}>
            24/7{" "}
            <b className={`${classes[("text247", "colortext")]}`}>
              Doctor On Call
            </b>
          </p>
          <p className={`${classes["truetabhealthtext"]}`}>
            TrueTab now brings you healthcare at your fingertips with our Doctor
            On Call service.
          </p>
          <div style={{ alignItems: "center", margin: "30px " }}>
            <ImageComponent
              src={twoMobile}
              className={`${classes["commonmobile"]}`}
            />
          </div>
        </div>
        <div className={`${classes["rightWarp27"]}`}>
          <div
            style={{
              backgroundColor: "#00000069",
              padding: "25px",
              margin: "15px",
              borderRadius: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
              <ImageComponent src={clock} width={"15px"} height={"15px"} />
              <p className={`${classes["avlabletextrightwap"]}`}>
                Available Round-the-Clock
              </p>
            </div>
            <div style={{ marginTop: "10px", marginLeft: "35px" }}>
              <p className={`${classes["avlabletexTsubtrightwap"]}`}>
                Talk to doctors anytime, anywhere. Because life doesn’t wait,
                and neither should your healthcare.
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#00000069",
              padding: "25px",
              margin: "15px",
              borderRadius: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
              <ImageComponent src={man_image} width={"15px"} height={"15px"} />
              <p className={`${classes["avlabletextrightwap"]}`}>
                Specialist Support
              </p>
            </div>
            <div style={{ marginTop: "10px", marginLeft: "35px" }}>
              <p className={`${classes["avlabletexTsubtrightwap"]}`}>
                From general practitioners to specialists, get expert advice on
                demand.
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#00000069",
              padding: "25px",
              margin: "15px",
              borderRadius: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
              <ImageComponent
                src={digital_priscription}
                width={"15px"}
                height={"15px"}
              />
              <p className={`${classes["avlabletextrightwap"]}`}>
                Quick Digital Prescriptions
              </p>
            </div>
            <div style={{ marginTop: "10px", marginLeft: "35px" }}>
              <p className={`${classes["avlabletexTsubtrightwap"]}`}>
                Receive prescriptions instantly and order medications
                seamlessly.
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#00000069",
              padding: "25px",
              margin: "15px",
              borderRadius: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
              <ImageComponent src={languag} width={"15px"} height={"15px"} />
              <p className={`${classes["avlabletextrightwap"]}`}>
                Speak Your Language
              </p>
            </div>
            <div style={{ marginTop: "10px", marginLeft: "35px" }}>
              <p className={`${classes["avlabletexTsubtrightwap"]}`}>
                Multi-lingual service for clear communication.
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#00000069",
              padding: "25px",
              margin: "15px",
              borderRadius: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
              <ImageComponent src={home} width={"15px"} height={"15px"} />
              <p className={`${classes["avlabletextrightwap"]}`}>
                Convenience at Its Best
              </p>
            </div>
            <div style={{ marginTop: "10px", marginLeft: "35px" }}>
              <p className={`${classes["avlabletexTsubtrightwap"]}`}>
                Consult from the comfort of your home
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        <p className={`${classes["moretext"]}`}>
          More Ways We <b style={{ color: "#A1C43A" }}>Care</b> for You
        </p>
        <p className={`${classes["meetevrytext"]}`}>
          Your health journey made simpler with a range of features designed to
          meet every need.
        </p>
      </div>
      <div
        style={{
          margin: "30px 15px",
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {(cardData || []).map((cardDetails, index) => (
          <div
            className={
              hoverStates === cardDetails?.id
                ? `${classes["hoverMoreDivData"]}`
                : `${classes["MoreDivData"]}`
            }
            onMouseEnter={() => handleMouseEnter(cardDetails)}
            onMouseLeave={() => setHoverStates(1)}
          >
            <div
              style={{
                backgroundColor:
                  hoverStates === cardDetails?.id ? "#FAFFEC36" : "#FAFFECE5",
                width: "60px",
                height: "60px",
                alignContent: "center",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: "15px",
              }}
            >
              <ImageComponent
                src={
                  hoverStates === cardDetails?.id
                    ? cardDetails?.hoverImage
                    : cardDetails?.normalImage
                }
                alt="img"
                width={"30px"}
                height={"30px"}
              />
            </div>
            <div style={{ marginTop: "30px" }}>
              <p
                className={`${classes["healthtext"]}`}
                style={{
                  color:
                    hoverStates === cardDetails?.id ? "#ffffff" : "#000000", // Text color changes on hover
                  transition: "color 0.3s ease",
                }}
              >
                {cardDetails?.title || ""}
              </p>
              <p
                className={`${classes["subtitel"]}`}
                style={{
                  color:
                    hoverStates === cardDetails?.id ? "#ffffff" : "#000000", // Text color changes on hover
                  transition: "color 0.3s ease",
                }}
              >
                {cardDetails?.subtitle || ""}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div
        style={{
          backgroundColor: "#F8F5FF",
          padding: "25px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div className={`${classes["whychoosetext"]}`}>
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: "42px",
              fontWeight: 500,
              lineHeight: "60px",
              color: "#000000",
            }}
          >
            Why Choose <b style={{ color: "#673AB7" }}>Us?</b>
          </p>
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "27px",
            }}
          >
            Whether you’re a healthcare provider aiming to grow or an individual
            seeking seamless access to care, we’re here to revolutionize
            healthcare delivery across India.
          </p>
        </div>
        <div className={`${classes["whychoosecontainer"]}`}>
          <div className={`${classes["whychooseleftwap"]}`}>
            <div
              style={{
                display: "flex",
                gap: "15px",
                marginBottom: "15px",
              }}
            >
              <div className={`${classes["chhoseIconediv"]}`}>
                <img src={thumbup} style={{ height: "40%", width: "40%" }} />
              </div>
              <div>
                <p className={`${classes["effortlestext"]}`}>
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#1D1B20CC",
                    }}
                  >
                    Effortless Access:{" "}
                  </span>{" "}
                  Consult doctors and order medications with just a few clicks.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "15px",
                marginBottom: "15px",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "40px",
                  borderRadius: "25px",
                  alignContent: "center",
                  // justifyItems: "center",
                  backgroundColor: "white",
                  textAlign: "center",
                }}
              >
                <img src={trusted} style={{ height: "40%", width: "40%" }} />
              </div>
              <div>
                <p className={`${classes["effortlestext"]}`}>
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#1D1B20CC",
                    }}
                  >
                    Trusted Experts:{" "}
                  </span>{" "}
                  Get reliable advice and care from India’s top professionals.
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: "15px",
                marginBottom: "20px",
              }}
            >
              <div className={`${classes["chhoseIconediv"]}`}>
                <img src={convinence} style={{ height: "55%", width: "55%" }} />
              </div>
              <div>
                <p className={`${classes["effortlestext"]}`}>
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#1D1B20CC",
                    }}
                  >
                    Convenience Delivered:{" "}
                  </span>{" "}
                  From consultations to doorstep delivery, we make it easy.
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: "15px",
                marginBottom: "20px",
              }}
            >
              <div className={`${classes["chhoseIconediv"]}`}>
                <img src={simply} style={{ height: "50%", width: "50%" }} />
              </div>
              <div>
                <p className={`${classes["effortlestext"]}`}>
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#1D1B20CC",
                    }}
                  >
                    Simplify Operations:{" "}
                  </span>{" "}
                  Manage consultations, prescriptions, and patient data
                  effortlessly.
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: "15px",
                marginBottom: "20px",
              }}
            >
              <div className={`${classes["chhoseIconediv"]}`}>
                <img src={expand} style={{ height: "50%", width: "50%" }} />
              </div>
              <div>
                <p className={`${classes["effortlestext"]}`}>
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#1D1B20CC",
                    }}
                  >
                    Expand Your Reach:{" "}
                  </span>{" "}
                  Connect with more patients through a seamless online platform.
                </p>
              </div>
            </div>
          </div>
          <div className={`${classes["whychooserightwap"]}`}>
            <ImageComponent
              src={our_choice}
              className={`${classes["manimage"]}`}
            />
          </div>
        </div>
      </div>
      {/* <TrueTabNewsComponent /> */}
      <div
        style={{
          backgroundImage: `url(${lastBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "400px",
          padding: "15px",
          // textAlign: "center",
        }}
      >
        <div className={`${classes["partoftextcontainer"]}`}>
          <p className={`${classes["partoftext"]}`}>
            Be A Part Of India’s
            <b style={{ color: "#522582" }}> Healthcare Revolution</b>
          </p>
          <div className={`${classes["downloadtext"]}`}>
            Download the app now!
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              margin: "20px",
            }}
          >
            <div className={`${classes["buttoncontainer"]}`}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    gap: "10px",
                    // textAlign: "center",
                  }}
                >
                  <ImageComponent
                    src={playstor}
                    alt="img"
                    width={"20px"}
                    height={"20px"}
                  />
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontSize: 8, color: "white" }}>Get it On</div>
                    <div style={{ fontSize: 10, color: "white" }}>
                      Google Play store
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes["buttoncontainer"]}`}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <ImageComponent
                    src={playstor}
                    alt="img"
                    width={"20px"}
                    height={"20px"}
                  />
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontSize: 8, color: "white" }}>Get it On</div>
                    <div style={{ fontSize: 10, color: "white" }}>
                      Google Play store
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
