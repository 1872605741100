import { createContext, useState } from "react";
const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [openSendEmail, setOpenSendEmail] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        openSendEmail,
        setOpenSendEmail,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export default GlobalContext;
