import React, { useState } from "react";
import classes from "./HeaderComponent.module.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../helper/dimensionsHelpers";
import Hamburger from "hamburger-react";
import { CalcWidthValue } from "../helper/CommonValue";

const NavBarComponent = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const [openBurger, setOpenBurger] = useState(false);

  const navigationOptions = [
    {
      id: 1,
      title: "Home",
      pageUrl: "/",
    },
    {
      id: 2,
      title: "About Us",
      pageUrl: "/about_us",
    },
    // {
    //   id: 3,
    //   title: "News",
    //   pageUrl: "/news",
    // },
    // {
    //   id: 4,
    //   title: "Blog",
    //   pageUrl: "/blogs",
    // },
    {
      id: 3,
      title: "Contact",
      pageUrl: "/contact",
    },
  ];

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div className={classes["nav-burger"]}>
        <div
          onClick={() => {
            setOpenBurger(!openBurger);
          }}
        >
          <Hamburger toggled={openBurger} color="#522582" size={20} />
        </div>
      </div>

      <ul
        className={classes["nav_container"]}
        style={{
          justifyContent: "flex-end",
          listStyle: "none",
        }}
      >
        {navigationOptions?.map((value) => {
          return (
            <li key={value?.id}>
              <NavLink to={value?.pageUrl}>
                <span
                  className={
                    location?.pathname === value?.pageUrl
                      ? classes["menu-options-text-active"]
                      : classes["hover-line"]
                  }
                >
                  {value?.title}
                </span>
              </NavLink>
            </li>
          );
        })}
      </ul>

      {openBurger && (
        <ul
          className={classes["nav_container_mobile"]}
          style={{
            justifyContent: "flex-end",
            listStyle: "none",
          }}
        >
          {navigationOptions?.map((value) => {
            return (
              <li
                key={value?.id}
                onClick={() => {
                  setOpenBurger(!openBurger);
                }}
              >
                <NavLink to={value?.pageUrl}>
                  <span
                    className={
                      location?.pathname === value?.pageUrl
                        ? classes["menu-options-text-active"]
                        : classes["hover-line"]
                    }
                  >
                    {value?.title}
                  </span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default NavBarComponent;
