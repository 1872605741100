import React, { useCallback, useContext, useState } from "react";
import classes from "./ContactComponent.module.css";
import ImageComponent from "../common/ImageComponent";
import useWindowDimensions from "../helper/dimensionsHelpers";
import GlobalContext from "../contaxt/GlobalContext";
import { apple, lastBackground, playstor } from "../assets";
import { displayToast } from "../common/toast/toastUtils";
import { sendEnquirywebsiteDemo } from "../../api/common";
import { isValidPhoneNumber, validateEmail } from "../helper/CommonValue";

const ContactComponent = () => {
  const { width } = useWindowDimensions();
  const { openSendEmail, setOpenSendEmail } = useContext(GlobalContext);

  const [name, setName] = useState({ value: "", isError: false });
  const [email, setEmail] = useState({
    value: "",
    isError: false,
    helperText: "",
  });
  const [lastName, setLastName] = useState({ value: "", isError: false });
  const [litSubject] = useState({ value: "", isError: false });
  const [message, setMessage] = useState({ value: "", isError: false });
  const [mobile, setMobile] = useState({
    value: "",
    isError: false,
    helperText: "",
  });

  const ValidationForm = useCallback(() => {
    let isErrorAvailable = true;
    if (!name?.value?.length) {
      setName({ value: "", isError: true });
      isErrorAvailable = false;
      // return false;
    }
    if (!email?.value?.length) {
      setEmail({
        value: "",
        isError: true,
        helperText: "Please Enter Email Id.",
      });
      isErrorAvailable = false;
    }
    if (!mobile?.value?.length) {
      setMobile({
        value: "",
        isError: true,
        helperText: "Please Enter Mobile No.",
      });
      isErrorAvailable = false;
    } else {
      if (!isValidPhoneNumber(mobile?.value)) {
        setMobile({
          value: mobile?.value,
          isError: true,
          helperText: "Please Enter Valid Mobile No.",
        });
        isErrorAvailable = false;
      }
    }
    if (!message?.value?.length) {
      setMessage({ value: "", isError: true });
      isErrorAvailable = false;
    }
    return isErrorAvailable;
  }, [
    email?.value,
    message?.value?.length,
    mobile?.value,
    name?.value?.length,
  ]);

  const onSubmitHandler = useCallback(async () => {
    if (ValidationForm()) {
      const payload = {
        name: name?.value + " " + lastName?.value,
        email: email?.value,
        mobile_no: mobile?.value,
        message: message?.value,
      };
      const response = await sendEnquirywebsiteDemo(payload);
      if (response.ok) {
        const allData = response?.data;
        if (allData?.status) {
          displayToast({
            type: "success",
            title: "Message send successfully",
          });
          setName({ value: "", isError: false });
          setLastName({ value: "", isError: false });
          setEmail({ value: "", isError: false });
          setMobile({ value: "", isError: false });
          setMessage({ value: "", isError: false });
        } else {
          displayToast({
            type: "error",
            title: allData?.message,
          });
        }
      }
    } else {
      displayToast({
        type: "error",
        title: "Something went Wrong",
      });
    }
    // }
  }, [
    ValidationForm,
    email?.value,
    lastName?.value,
    message?.value,
    mobile?.value,
    name?.value,
  ]);

  return (
    <section>
      <div className={classes["contact_container"]}>
        {/* left side */}
        <div
          style={{
            textAlign: "center",
            // width: "100%",
          }}
        >
          <p className={classes["contecttext"]}>Contact Us</p>
          <p className={classes["anyQyation"]}>
            Any question or remarks? Just write us a message!
          </p>
        </div>

        <div className={classes["contextdiv"]}>
          <div className={classes["leftWrap"]}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d269.47916598770945!2d72.88492524846617!3d19.101426530928745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c86e662be55b%3A0x6e34bc9b6cafe76f!2sDilkap%20Center!5e0!3m2!1sen!2sin!4v1736763494068!5m2!1sen!2sin"
              className={classes["mapDiv"]}
              allowFullScreen=""
              loading="lazy"
              title="myFrame"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div style={{ margin: "10px" }}>
            <div style={{ display: "flex", gap: "50px", flexWrap: "wrap" }}>
              <div className={classes["textInputView"]}>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "#1D1B20CC",
                  }}
                >
                  Full Name
                </p>
                <input
                  type="text"
                  style={{
                    border: "none", // Removes the border
                    outline: "none", // Removes the focus outline (optional)
                  }}
                  value={name?.value}
                  onChange={(event) =>
                    setName({ value: event.target.value, isError: false })
                  }
                />
              </div>

              <div className={classes["textInputView"]}>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "#1D1B20CC",
                  }}
                >
                  Last Name
                </p>
                <input
                  type="text"
                  style={{
                    border: "none", // Removes the border
                    outline: "none", // Removes the focus outline (optional)
                  }}
                  value={lastName.value}
                  onChange={(event) =>
                    setLastName({ value: event.target.value, isError: false })
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "50px",
                marginTop: "15px",
                flexWrap: "wrap",
              }}
            >
              <div className={classes["textInputView"]}>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "#1D1B20CC",
                  }}
                >
                  Email ID
                </p>
                <input
                  type="text"
                  style={{
                    border: "none", // Removes the border
                    outline: "none", // Removes the focus outline (optional)
                  }}
                  value={email.value}
                  onChange={(event) =>
                    setEmail({ value: event.target.value, isError: false })
                  }
                />
              </div>

              <div className={classes["textInputView"]}>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    color: "#1D1B20CC",
                  }}
                >
                  Phone Number
                </p>
                <input
                  type="text"
                  style={{
                    border: "none", // Removes the border
                    outline: "none", // Removes the focus outline (optional)
                  }}
                  value={mobile.value}
                  onChange={(event) =>
                    setMobile({ value: event.target.value, isError: false })
                  }
                />
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #000000", // Only bottom border
                marginTop: "15px",
                height: "55px",
                width: "100%",
                // display: "flex",
                // flexDirection: "column", // Ensure proper layout for <p> and <input>
              }}
            >
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  lineHeight: "20px",
                  fontWeight: 400,
                  color: "#1D1B20CC",
                }}
              >
                Message
              </p>
              <input
                type="text"
                style={{
                  border: "none",
                  outline: "none",
                }}
                value={message.value}
                onChange={(event) =>
                  setMessage({ value: event.target.value, isError: false })
                }
              />
            </div>
            <div className={classes["buttonDiv"]}>
              <div
                className={classes["contact_button"]}
                onClick={() => onSubmitHandler()}
              >
                Send Message
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${lastBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "400px",
          padding: "15px",
          // textAlign: "center",
        }}
      >
        <div className={`${classes["partoftextcontainer"]}`}>
          <p className={`${classes["partoftext"]}`}>
            Be A Part Of India’s
            <b style={{ color: "#522582" }}> Healthcare Revolution</b>
          </p>
          <div className={`${classes["downloadtext"]}`}>
            Download the app now!
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              margin: "20px",
            }}
          >
            <div className={`${classes["buttoncontainer"]}`}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    gap: "10px",
                    // textAlign: "center",
                  }}
                >
                  <ImageComponent
                    src={playstor}
                    alt="img"
                    width={"20px"}
                    height={"20px"}
                  />
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontSize: 8, color: "white" }}>Get it On</div>
                    <div style={{ fontSize: 10, color: "white" }}>
                      Google Play store
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes["buttoncontainer"]}`}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <ImageComponent
                    src={apple}
                    alt="img"
                    width={"20px"}
                    height={"20px"}
                  />
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontSize: 8, color: "white" }}>Get it On</div>
                    <div style={{ fontSize: 10, color: "white" }}>
                      Google Play store
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className={classes["contact_leftSide"]}>
          <ImageComponent
            src={Contact_IMG}
            alt={"logo"}
            className={classes["contact_img"]}
            width="570px"
          /> 
        </div> */}
      {/* right side */}
      {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "50px 80px 0px 60px",
            height: "auto",
          }}
        >
          <div
            style={{
              height: "402px",
            }}
          >
            <div style={{ marginBottom: "15px" }}>
              <IoLocationOutline color="#6735B9" size={50} />
            </div>
            <div>
              <h4 className={classes["contact-text-header"]}>
                Corporate Office
              </h4>
              <div className={classes["contact_trutab_add"]}>
                Truetab Private Limited HD-009, We Work, K. Raheja Platinum, Sag
                Baug Road, Mumbai, India
              </div>
              <h4 className={classes["contact-text-header"]}>
                Regional Office
              </h4>
              <div className={classes["contact_trutab_add"]}>
                E 52, Info City, Patia Bhubaneswar.
              </div>
            </div>
            <div style={{}}>
          
            </div>
          </div>
        </div> */}

      {/* <div style={{ height: "500px", width: "100%" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1870.533057305653!2d85.80346879591798!3d20.338887219856932!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19097bb501f957%3A0xe0643aa8db3673ac!2sTruetab!5e0!3m2!1sen!2sin!4v1689059841091!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
          title="myFrame"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div> */}
    </section>
  );
};

export default ContactComponent;
