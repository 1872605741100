import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomeComponent from "./component/Home/HomeComponent";
import MainContainer from "./component/MainContainer";
import BlogComponent from "./component/Blog/BlogComponent";
import ContactComponent from "./component/Contact/ContactComponent";
import AboutUsComponent from "./component/AboutUS/AboutUsComponent";
import NewsComponent from "./component/News/NewsComponent";
import DeleteUserComponent from "./component/DeleteUser/DeleteUserComponent";
import ShippingReturnPolicy from "./component/ShippingReturnPolicy/ShippingReturnPolicy";
import TermsCondition from "./component/TermsCondition/TermsConditions";
import PrivacyPolicy from "./component/PrivacyPolicy/PrivacyPolicy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<MainContainer />}>
          <Route index path={"/"} element={<HomeComponent />} />
          <Route index path={"/blogs"} element={<BlogComponent />} />
          <Route index path={"/contact"} element={<ContactComponent />} />
          <Route index path={"/about_us"} element={<AboutUsComponent />} />
          <Route index path={"/news"} element={<NewsComponent />} />
          <Route
            index
            path={"/contact-us-delete-app-user"}
            element={<DeleteUserComponent />}
          />
          <Route
            index
            path={"/shipping-and-returns-policy"}
            element={<ShippingReturnPolicy />}
          />
          <Route index path={"/privacy-policy"} element={<PrivacyPolicy />} />
          <Route
            index
            path={"/terms-and-conditions"}
            element={<TermsCondition />}
          />
        </Route>
        <Route path={"/*"} element={<Navigate to={"/"} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
