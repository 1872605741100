import errorIcon from "./errorIcon.svg";
import successIcon from "./successIcon.svg";
import warningIcon from "./warningIcon.svg";
import infoIcon from "./infoIcon.svg";
import simply from "./simply.svg";
import thumbup from "./thumbup.svg";
import trusted from "./trusted.svg";
import expand from "./Expand.svg";
import convinence from "./convinence.svg";

const images = {
  errorIcon,
  successIcon,
  warningIcon,
  infoIcon,
  simply,
  thumbup,
  trusted,
  expand,
  convinence,
};
export default images;
